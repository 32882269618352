h1 {
  font-size: 110px;
  font-family: 'Source Sans Pro', sans-serif;
}

p {
  margin: 10px 0px;
  font-size: 100%;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
}

a:link {
  color: white;
}

a:visited {
  color: white;
}

.app {
  text-align: center;
  overflow: hidden;
}

.profile-pic {
  border-radius: 20px;
  object-fit: fill;
  max-width: 100%;
}

.splash-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.background-highlight-down {
  background: linear-gradient(315deg, rgb(39, 28, 104), rgb(7, 11, 46) 70.71%);
}

.background-basic {
  background-color: rgb(212, 159, 205);
}

.background-highlight-up {
  background: linear-gradient(45deg, rgb(113, 163, 233), rgb(7, 23, 46) 70.71%);
}

.about-me {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.about-me-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: linear-gradient(215deg, rgb(161, 6, 115), rgb(8, 38, 80) 100%);
  margin: 30px;
  height: 70vh;
  width: 70vw;
  border-radius: 40px;
  overflow: hidden;
}

.about-me-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 40%;
  text-align: left;
  padding: 5%;
}

.about-me-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%
}

.about-me-main-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  flex: 1;
}

.divider {
  height: 1px;
  width: 30%;
  background-color: white;
  margin: 10px 0px;
}

.card-title {
  margin-bottom: -70px;
  width: 100%;
  text-align: left;
  padding-left: 8%;
  z-index: 10;
}

.additional-info {
  font-size: 80%;
}

.link-grid {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.link-icon {
  margin-top: 30px;
  padding: 10px;
}

.feather {
  width: 24px;
  height: 24px;
  stroke: white;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

@media (max-width: 993px) {
  .about-me-box {
    flex-direction: column;
    width: 100%;
    margin: 0px;
    height: auto;
    margin-bottom: 10px;
    background: linear-gradient(215deg, rgb(104, 8, 91), rgb(8, 38, 80) 50%);
  }

  .about-me-left {
    width: 90%;
    height: 50%;
    padding-top: 40px;
  }
  
  .about-me-right {
    width: 110%;
    height: 30%;
  }

  .card-title {
    margin-bottom: -15px;
    width: 100%;
    text-align: left;
    padding-left: 10%;
    z-index: 10;
  }

  h1 {
    font-size: 40px;
  }
}